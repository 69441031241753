<template>
  <div v-if="!mounted">En cours de chargement...</div>
  <div v-else class="affiliation__info_generale">
    <v-row>
      <v-col cols="10">
        <v-alert border="left" :color="colorEtapeAffiliation">
          <p class="mb-0">Affiliation saison {{ this.selectedSeason.id }}</p>
          <p class="mb-0 font-weight-bold">{{ currentEtapeAffiliation.NomEtapeAffiliation }}</p>
        </v-alert>
      </v-col>
    </v-row>

    <h1 class="font-weight-medium" style="font-size: 20px;">Étape 1 - Saisie des informations générales</h1>

    <ValidateForm @validated="submit" :showFieldObl="false" :loading="loading">
      <v-row class="mt-3">
        <v-col cols="12" class="pb-0">
          <h2 class="mb-0" style="font-size: 12px; letter-spacing: 1px;">Informations de correspondance</h2>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col cols="12" class="pb-0">
              <p class="font-weight-bold">Correspondant *</p>
            </v-col>
            <v-col cols="12">
              <userAssign
                :user="currentNomCorrespondant"
                :context="{
                  hasCodeFederal,
                  ID_Structure: currentStructureId,
                  ID_Saison: selectedSeason.id,
                }"
                @userSelected="userSelectedNomCorrespondant"
                :disabled="disabled"
                :showAvatar="false"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <v-row>
            <v-col cols="12" class="pb-0">
              <p class="font-weight-bold">Adresse de correspondance *</p>
            </v-col>
            <v-col cols="12" class="pb-0">
              <assignCard
                :context="{
                  ID_Structure: currentStructureId,
                  title: 'Adresse correspondant',
                  key: 'ID_AdresseCorrespondance',
                  type: 'correspondance',
                }"
                :adresse="adresseCorrespondance()"
                :structureAdresses="adresses"
                @onCardUpdate="setData"
              ></assignCard>
            </v-col>
          </v-row>
          <div class="mt-4">
            <v-btn class="btn btn-primary" @click="openAddModal(0)" tile
              >Ajouter une adresse</v-btn
            >
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="12" class="pb-0">
          <h2 class="mb-0" style="font-size: 12px; letter-spacing: 1px;">Informations officielles</h2>
        </v-col>

        <v-col cols="6">
          <inputText
            v-model="JUR_Siret"
            label="N° SIRET"
            rules="siret"
            :disabled="disabled"
          />
        </v-col>
        <v-col cols="6">
          <inputText
            v-model="JUR_AgrementJeunesseSports"
            label="Agrément Jeunesse et sports"
            :disabled="disabled"
          />
        </v-col>
      </v-row>

      <v-row v-if="!isStructureEA" class="mt-4">
        <v-col cols="12" class="pb-0">
          <h2 class="mb-0" style="font-size: 12px; letter-spacing: 1px;">Membres du bureau</h2>
        </v-col>
        <v-col cols="12" lg="4">
          <p>Président</p>
          <userAssign
            :user="userPresident"
            :context="{
              hasCodeFederal,
              ID_Structure: currentStructureId,
              ID_Saison: selectedSeason.id,
            }"
            @userSelected="userFonctionPresidentSelected"
            :disabled="disabled"
            :showAvatar="false"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <p>Secrétaire</p>
          <userAssign
            :user="userSecretaire"
            :context="{
              hasCodeFederal,
              ID_Structure: currentStructureId,
              ID_Saison: selectedSeason.id,
            }"
            @userSelected="userFonctionSecretaireSelected"
            :disabled="disabled"
            :showAvatar="false"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <p>Trésorier</p>
          <userAssign
            :user="userTresorier"
            :context="{
              hasCodeFederal,
              ID_Structure: currentStructureId,
              ID_Saison: selectedSeason.id,
            }"
            @userSelected="userFonctionTresorierSelected"
            :disabled="disabled"
            :showAvatar="false"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 15px;">
          <v-btn
            :disabled="!hasCodeFederal || disabled"
            v-if="canSubmit"
            type="submit"
            :loading="loading"
            class="btn btn-primary mt-2"
          >
            Enregistrer les informations de cette étape
          </v-btn>
          <v-btn
            :disabled="!hasCodeFederal || disabled || !stepIsValid"
            v-if="canSubmit"
            type="button"
            :loading="loading"
            class="btn btn-primary mt-2"
            @click="() => { this.$router.push({ name: 'affiliations__activites_du_club' }) }"
          >
            Etape suivante
          </v-btn>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn
                  type="submit"
                  :disabled="true"
                  class="btn btn-primary mt-2"
                >
                  Enregistrer les informations de cette étape
                </v-btn>
              </span>
            </template>
            <span
              >Vous n'avez pas les droits nécessaire pour accéder à ce module.
              Merci de contacter un administrateur.</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
    </ValidateForm>

    <!--  Passer l'id de la fonction a assigner, à l'interieur du component, utilisé la modal user pour la selection -->
    <!--  Passer egalement l'id de la strucutre que l'on souhaite update.
  !!! cela doit aussi changer la liste des users de la modal -->
    <!--  Faire remonter les events jusqu'a ce component et stocké dans une clé ex: currentUserPresident  -->
    <!--  Stocker le components ddans components/User/User__AssignFonction  -->
    <AdresseModal
      :isOpen="modalAddAddressIsOpen"
      :context="{ ID_Structure: currentStructureId }"
      @onClose="modalAddAddressIsOpen = false"
      @onSelected="onItemSelected"
      :tabInit="tabInit"
      @onUpdated="onUpdated"
      ref="adresseModal"
      :affiliation="true"
      @onCardAddressUpdate="adresseCorrespondance()"
    ></AdresseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import userAssign from "@/components/User/User__AssignUser.vue";
// import adressAssign from "@/components/Adresse/Adresse__AssignAdresse.vue";
// import inputSelect from '@/components/utils/select'
import inputText from "@/components/utils/input";
import ValidateForm from "@/components/utils/form.vue";
import assignCard from "@/components/Adresse/Adresse__SelectAssignCard.vue";
import AdresseModal from "@/components/utils/modals/adresses.vue";
import StructureType from "@/constants/structureType";
import { bus } from "@/main";

export default {
  components: {
    inputText,
    userAssign,
    /*adressAssign,*/ ValidateForm,
    assignCard,
    AdresseModal,
  },

  data: () => ({
    mounted: false,
    loading: false,
    structure: null,
    adresses: null,
    affiliation: null,

    JUR_AgrementJeunesseSports: null,
    JUR_Siret: null,

    currentNomCorrespondant: null,
    currentAdresseCorrespondance: null,
    userPresident: null,
    userSecretaire: null,
    userTresorier: null,

    userCurrentPresident: null,
    userCurrentSecretaire: null,
    userCurrentTresorier: null,

    etapes_affilliations: [],
    ID_FonctionPresident: null,
    ID_FonctionSecretaire: null,
    ID_FonctionTresorier: null,

    UTI_Utilisateurs: [],
    modalAddAddressIsOpen: false,
    tabInit: 0,

    stepIsValid: false,
    selectedSeason: null,
  }),

  async created() {
    bus.$on("onStructureAdresseUpdated", async () => {
      return await this.setData();
    });
    await this.getListEtapeAffiliation();
    let allFonctionList = await this.getListTypeFonctions();

    this.ID_FonctionPresident = allFonctionList.find(
      (fct) => fct.SlugFonction == "president"
    ).id;

    this.ID_FonctionSecretaire = allFonctionList.find(
      (fct) => fct.SlugFonction == "bureau_secretaire"
    ).id;

    this.ID_FonctionTresorier = allFonctionList.find(
      (fct) => fct.SlugFonction == "bureau_tresorier"
    ).id;

    await this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", [
      "getStructure",
      "currentStructure",
      "currentStructureId",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["saisons", "currentSaison"]),
    ...mapGetters("user", ["can"]),

    canSubmit() {
      return this.can("affiliations__informations_generales");
    },

    disabled() {
      if (!this.affiliation) return false;
      return this.affiliation.ID_EtapeAffiliation == 3;
    },

    colorEtapeAffiliation() {
      if (!this.currentEtapeAffiliation) return;
      let slug = this.currentEtapeAffiliation.SlugEtapeAffiliation;
      if (slug == "en_attente") return "grey lighten-2";
      if (slug == "en_cours_de_preparation") return "blue";
      if (slug == "ouverture_des_pre_renouvellements_de_licencies")
        return "gray";
      if (slug == "pre_affiliation_termine") return "green";
      return "gray";
    },

    isStructureEA() {
      return this.structure.STR_Structure.ID_TypeStructure === StructureType.ClubEtablissementAffilie;
    },
  },

  methods: {
    ...mapActions("basics", [
      "getListEtapeAffiliation",
      "getListTypeFonctions",
    ]),
    ...mapActions("utilisateurs", [
      "getUtlisateursInStructure",
      "getListDirigeants",
    ]),
    ...mapActions("structure", [
      "getStructureAffiliation",
      "getStructureMembre",
      "getStructureAdresses",
      "updateUserFonctionOnStructure",
      "updateAffiliationInformationGeneral",
      "insertStructureAffiliation",
    ]),

    async setData() {
      this.selectedSeason = this.saisons.find(
        (season) => season.id === parseInt(this.$route.params.seasonId, 10)
      );

      this.affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSeason.id,
      });

      if (!this.affiliation) {
        this.affiliation = await this.insertStructureAffiliation({
          ID_Structure: this.currentStructureId,
          ID_Saison: this.selectedSeason.id,
        });
      }

      let payload = {};
      let arrayClause = {
        EST_Actif: { _eq: true },
      };
      arrayClause.ID_Structure = { _eq: this.currentStructureId };
      payload.where = { ...arrayClause };
      this.UTI_Utilisateurs = await this.getListDirigeants(payload);

      this.structure = { ...this.currentStructure };

      this.adresses = await this.getStructureAdresses({
        ID_Structure: this.currentStructureId,
      });

      this.JUR_AgrementJeunesseSports =
        this.structure.STR_Structure.JUR_AgrementJeunesseSports;
      this.JUR_Siret = this.structure.STR_Structure.JUR_Siret;

      if (this.affiliation.ID_EtapeAffiliation) {
        this.currentEtapeAffiliation = {
          ...this.getList.LIST_EtapeValidationAffiliation.find(
            (item) => item.id == this.affiliation.ID_EtapeAffiliation
          ),
        };
      }

      if (this.structure.STR_Structure.ID_NomCorrespondant) {
        let user = {
          ...this.UTI_Utilisateurs.find(
            (user) =>
              user.ID_Utilisateur ==
              this.structure.STR_Structure.ID_NomCorrespondant
          ),
        };
        //console.log("lalalaa", this.UTI_Utilisateurs)

        if (user.ID_Utilisateur) this.currentNomCorrespondant = user;
        //console.log("correspondant",this.currentNomCorrespondant)
      }

      if (this.structure.STR_Structure.ID_AdresseCorrespondance) {
        this.currentAdresseCorrespondance = {
          ...this.adresses.find(
            (adr) =>
              adr.id == this.structure.STR_Structure.ID_AdresseCorrespondance
          ),
        };
      }

      this.userCurrentPresident = this.UTI_Utilisateurs.find(
        (user) => user.ID_Fonction == this.ID_FonctionPresident
      );
      this.userCurrentSecretaire = this.UTI_Utilisateurs.find(
        (user) => user.ID_Fonction == this.ID_FonctionSecretaire
      );
      this.userCurrentTresorier = this.UTI_Utilisateurs.find(
        (user) => user.ID_Fonction == this.ID_FonctionTresorier
      );

      if (this.userCurrentPresident)
        this.userPresident = { ...this.userCurrentPresident };
      if (this.userCurrentSecretaire)
        this.userSecretaire = { ...this.userCurrentSecretaire };
      if (this.userCurrentTresorier)
        this.userTresorier = { ...this.userCurrentTresorier };

      this.validateStep();

      return;
    },

    async submit() {
      this.loading = true;
      try {
        let payload = {
          ID_Affiliation: this.affiliation.id,
          ID_Saison: this.selectedSeason.id,
          ID_Structure: this.currentStructureId,

          ID_EtapeAffiliation: this.currentEtapeAffiliation.id,

          ID_AdresseCorrespondance: this.currentAdresseCorrespondance
            ? this.currentAdresseCorrespondance.id
            : null,
          ID_NomCorrespondant: this.currentNomCorrespondant
            ? this.currentNomCorrespondant.ID_Utilisateur
            : null,
          JUR_Siret: this.JUR_Siret ? this.JUR_Siret : null,
          JUR_AgrementJeunesseSports: this.JUR_AgrementJeunesseSports,

          ID_newUserPresident: this.userPresident
            ? this.userPresident.ID_Utilisateur
            : null,
          ID_newUserSecretaire: this.userSecretaire
            ? this.userSecretaire.ID_Utilisateur
            : null,
          ID_newUserTresorier: this.userTresorier
            ? this.userTresorier.ID_Utilisateur
            : null,

          ID_FonctionPresident: this.ID_FonctionPresident,
          ID_FonctionSecretaire: this.ID_FonctionSecretaire,
          ID_FonctionTresorier: this.ID_FonctionTresorier,

          ID_CurrentUserPresident: this.userCurrentPresident
            ? this.userCurrentPresident.ID_Utilisateur
            : null,
          ID_CurrentUserSecretaire: this.userCurrentSecretaire
            ? this.userCurrentSecretaire.ID_Utilisateur
            : null,
          ID_CurrentUserTresorier: this.userCurrentTresorier
            ? this.userCurrentTresorier.ID_Utilisateur
            : null,
        };
        console.log("payload", payload);
        await this.updateAffiliationInformationGeneral(payload);
        this.setData();
        success_notification("La modification de l’étape est un succès, poursuivez votre réaffiliation en allant à l’étape suivante");
        this.loading = false;
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
        console.log(e);
      }

      this.validateStep();
    },

    async validateStep() {
      let fonctiontList = await this.getListTypeFonctions();
      let structure = await this.getStructure(this.currentStructureId);
      let payload = {
        where: {
          EST_Actif: {
            _eq: true
          },
          ID_Structure: {
            _eq: this.currentStructureId
          }
        }
      };
      let utilisateurs = await this.getListDirigeants(payload);

      let correspondant =
          this.UTI_Utilisateurs.find(
            (user) =>
              user.ID_Utilisateur ==
              structure.STR_Structure.ID_NomCorrespondant
          );

      let presidentType = fonctiontList.find(
        (fct) => fct.SlugFonction == "president"
      );
      if (
        correspondant &&
        structure.STR_Structure.ID_AdresseCorrespondance &&
        (utilisateurs.some((user) => user.ID_Fonction == presidentType.id) || this.isStructureEA)
      ) {
        this.stepIsValid = true;
        bus.$emit('onRefreshNavigation', true);
        return true;
      }

      this.stepIsValid = false;
      return false;
    },

    userSelectedNomCorrespondant(user) {
      this.currentNomCorrespondant = user;
    },

    async userFonctionPresidentSelected(user) {
      this.userPresident = user;
    },

    async userFonctionSecretaireSelected(user) {
      this.userSecretaire = user;
    },

    async userFonctionTresorierSelected(user) {
      this.userTresorier = user;
    },

    adresseSelected(adresse) {
      //console.log("adresse", adresse)
      this.currentAdresseCorrespondance = adresse;
    },
    adresseCorrespondance(adresseCorrespondance) {
      if (!adresseCorrespondance) {
        let ID_AdresseCorrespondance =
          this.currentStructure.STR_Structure.ID_AdresseCorrespondance;
        let adresse = this.adresses.find(
          (adr) => adr.id == ID_AdresseCorrespondance
        );

        this.currentAdresseCorrespondance = adresse;
        return adresse;
      } else adresseCorrespondance;
    },
    openAddModal(item) {
      this.tabInit = item;
      this.modalAddAddressIsOpen = true;
    },
    onItemSelected(item) {
      // console.log("pârent", item);
    },
    onUpdated() {
      this.modalAddAddressIsOpen = false;
    },
  },
};
</script>
