var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-row',{attrs:{"no-gutters":""}},[_c('SubBar',{attrs:{"title":"Historique des affiliations","route_name":"gestion_de_la_structure__dashboard"}})],1),(!_vm.mounted)?_c('div',{staticClass:"form-user-details__loading-container"},[_c('Loading',{attrs:{"loading":true}})],1):_vm._e(),(_vm.isStructureMA)?_c('div',{staticClass:"d-flex justify-content-end my-2"},[_c('Button',{staticClass:"btn-validation mr-2",class:{ disabled: !_vm.canReaffiliate },attrs:{"color":'#082B6E',"rounded":"","height":"48","type":"primary"},on:{"click":function($event){_vm.canReaffiliate ? (_vm.isReaffiliationMAOpen = true) : null}}},[_vm._v(" Ré-affilier la structure ")])],1):_vm._e(),_c('v-row',[(
        _vm.affiliationStructure &&
        _vm.affiliationStructure.STR_Affiliations &&
        _vm.affiliationStructure.STR_Affiliations.length > 0
      )?_c('v-col',{staticClass:"px-6"},[_c('HistoriqueLicence',{attrs:{"context":{
          NomStructure: _vm.getStructureName,
          CodeFederal: _vm.getStructureCode,
          affiliations: _vm.getAffiliation,
          // licenceNumber: licenceNumber,
        },"withTitle":false,"structure-fonction":_vm.fonctionUser}})],1):_c('v-col',{staticClass:"px-6"},[_c('p',[_vm._v("Votre historique d'affiliation est vide")])])],1),_c('ReaffiliationMA',{attrs:{"is-open":_vm.isReaffiliationMAOpen},on:{"close":function($event){_vm.isReaffiliationMAOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }